import React, { CSSProperties } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Ages from "./Ages";
import Allocation from "./Allocation";
import Expenses from "./Expenses";
import Income from "./Income";
import Savings from "./Savings";
import SectionHeader from "./SectionHeader";

function Investments() {
  const cardBodyStyle: CSSProperties = {
    paddingTop: "30px",
  };

  const componentStyle: CSSProperties = {
    paddingTop: "0px",
    paddingBottom: "35px",
  };

  return (
    <Container style={cardBodyStyle}>
      <Row>
        <Col lg>
          <Container>
            <Row>
              <Col>
                <SectionHeader title="Timespan">
                  The Start Age is the age at which the simulation will start.
                  Set the Start Age to the date you are planning to retire.
                  Additionally, you can set the Start Age to your current age
                  and adjust savings and income to current levels. The
                  simulation will take unspent income and invest it in
                  accordance with your investment allocations. The End Age is
                  the age you want to end the simulation.
                </SectionHeader>
              </Col>
            </Row>
            <Row>
              <Col style={componentStyle}>
                <Ages />
              </Col>
            </Row>
            <Row>
              <Col>
                <SectionHeader title="Savings">
                  When the Start Age represents your retirement age then set
                  this to what you hope to have saved at retirement. When the
                  Start Age represents your current age then set this to what
                  you have currently saved.
                  <br />
                  <br />
                  <b>Tip</b>: Dragging the range slider to the end will auto
                  adjust the scale of the slider.
                </SectionHeader>
              </Col>
            </Row>
            <Row>
              <Col style={componentStyle}>
                <Savings />
              </Col>
            </Row>
            <Row>
              <Col>
                <SectionHeader title="Allocations">
                  This is how your savings are invested either currently or what
                  you are targeting in retirement.
                </SectionHeader>
              </Col>
            </Row>
            <Row>
              <Col style={componentStyle}>
                <Allocation />
              </Col>
            </Row>
            <Row>
              <Col>
                <SectionHeader title="Expenses">
                  This is your total expenses for the year. You can increase
                  your odds of a successful retirement by reducing your expenses
                  following a year when investment growth is negative.
                </SectionHeader>
              </Col>
            </Row>
            <Row>
              <Col style={componentStyle}>
                <Expenses />
              </Col>
            </Row>
          </Container>
        </Col>
        <Col lg>
          <Container>
            <Row>
              <Col>
                <SectionHeader title="Income">
                  This is your sources of income. Set the age range when the
                  income will be earned. Income will be used before pulling from
                  your investments. If your income is greater than your expenses
                  the remainder will be invested in accordance with your
                  investment allocations.
                  <br />
                  <br />
                  <b>Tip</b>: Dragging the range slider to the end will auto
                  adjust the scale of the slider.
                </SectionHeader>
              </Col>
            </Row>
            <Row>
              <Col style={componentStyle}>
                <Income />
              </Col>
            </Row>
          </Container>
        </Col>
      </Row>
    </Container>
  );
}

export default Investments;
