import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import React, { useState } from "react";
import { Dictionary } from "../../store/types";
import { sliderStyle } from "../../styles";

interface DollarSliderSimpleProps {
  value: number;
  min: number;
  max: number;
  step: number;
  onChange(newValue: number): void;
  onAfterChange(): void
}

interface DollarSliderSimpleState {
  value: number;
  min: number;
  max: number;
  step: number;
  marks: {};
}

function DollarSliderSimple(props: DollarSliderSimpleProps) {
  const calculateState = (value: number): DollarSliderSimpleState => {
    const marks: Dictionary<string> = {}

    marks[props.min] = "$0";
    marks[props.max] = `$${props.max/1000}K`;
    
    return {
      min: props.min,
      max: props.max,
      step: props.step,
      value: value,
      marks: marks  
    };    
  };

  const [localState, setLocalState] = useState<DollarSliderSimpleState>(calculateState(props.value));

  const onChange = (value: number) => {
    setLocalState({...localState, value: value})
    props.onChange(value);
  };

  const onAfterChange = (value: number) => {
    setLocalState(calculateState(value));
    props.onAfterChange();
  };

  return (
    <Slider
      value={localState.value}
      min={0}
      max={localState.max}
      step={localState.step}
      marks={localState.marks}
      handleStyle={
        {
          height: sliderStyle.handleHeight,
          width: sliderStyle.handleWidth,
          marginLeft: sliderStyle.handleMarginLeft,
          marginTop: sliderStyle.handleMarginTop,
          borderColor: sliderStyle.handleDefaultColor,
          borderWidth: sliderStyle.handleBorderWidth
        }
      }
      trackStyle={
        {
          backgroundColor: sliderStyle.trackDefaultColor,
          height: sliderStyle.trackHeight,
          marginTop: sliderStyle.trackMarginTop
        }
      }
      onChange={onChange}
      onAfterChange={onAfterChange}
    />
  );
}

export default DollarSliderSimple;
