import React from "react";
import { MonteCarloSummaryItem, Settings } from "../../store/types";
import { Container, Row, Col } from "react-bootstrap";
import Format from "../Utils/Format";

export interface SummaryOverviewProps {
  settings: Settings;
  data: MonteCarloSummaryItem[];
}

export default function SummaryOverview(props: SummaryOverviewProps) {
  const getProbability = (value: MonteCarloSummaryItem): string => {
    if (value.startAmount === 0) return "0";

    return Format.toPercent((value.count / props.settings.numberOfSimulations) * 100, 1);
  };

  const renderMain = () => {
    let mainRowIndex = 0;

    if (props.data.length > 1) {
      mainRowIndex = 1;
    }

    return (
      <Row id="1">
        <Col className="text-center align-middle">
          <h1 className="display-1 font-weight-bold text-primary">{getProbability(props.data[mainRowIndex])}</h1>
          <div>
            <h4>Probability of funding your retirement after running {Format.toNumber(props.settings.numberOfSimulations)} simulations.</h4>
          </div>
        </Col>
      </Row>
    );
  };

  const renderRows = (startIndex: number, endIndex: number) => {
    let currentIndex = -1;

    return props.data.map(row => {
      currentIndex++;

      if (currentIndex >= startIndex && currentIndex <= endIndex) {
        return (
          <Row id={currentIndex.toString()} key={currentIndex} className="border-bottom">
            <Col>
              <div className="text-nowrap">
                <h4 className="float-left display-5">{getProbability(row)}</h4>
                <div className="float-left" style={{ marginLeft: 5, marginTop: 10, fontSize: "1.2rem" }}>
                  probability of balance more than <b>{Format.toDollars(row.startAmount)}</b>
                </div>
              </div>
            </Col>
          </Row>
        );
      }

      return null;
    });
  };

  return (
    <Container>
      <Row style={{ marginBottom: 25 }}>
        <Col className="text-center">
          <Container>{renderMain()}</Container>
        </Col>
      </Row>
      <Row>
        <Col lg>
          <Container>{renderRows(2, 9)}</Container>
        </Col>
        <Col lg>
          <Container>{renderRows(10, 17)}</Container>
        </Col>
      </Row>
    </Container>
  );
}
