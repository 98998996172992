import colors from "./bootstrap-colors.module.scss";

export interface SliderStyle {
  handleHeight: number;
  handleWidth: number;
  handleMarginLeft: number;
  handleMarginTop: number;
  handleDefaultColor: string;
  handleBorderWidth: number;
  trackDefaultColor: string;
  trackHeight: number;
  trackMarginTop: number;
}

export const sliderStyle: SliderStyle = {
  handleHeight: 20,
  handleWidth: 20,
  handleMarginLeft: -9,
  handleMarginTop: -9,
  handleDefaultColor: colors.green,
  handleBorderWidth: 3,
  trackDefaultColor: colors.green,
  trackHeight: 7,
  trackMarginTop: -2
};
