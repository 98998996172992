import "rc-slider/assets/index.css";
import React from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import { IncomeSource, Dictionary } from "../../store/types";
import IncomeItem from "./IncomeItem";
import { useSelector, useDispatch } from "react-redux";
import { AppState } from "../../store";
import { updateIncome } from "../../store/settings/settingsActions";

function Income() {
  const state = useSelector<AppState, Dictionary<IncomeSource>>(state => state.settings.income); 
  const dispatch = useDispatch();

  const onIncomeSourceAdd = () => {
    let newId: number = 0;

    for (const key in state) {
      const numberKey = Number(key) || 0;

      if (numberKey > newId) newId = numberKey;
    }

    newId++;

    const newIncome: IncomeSource = {
      id: newId,
      title: `Income ${newId}`,
      amount: 0,
      increase: 0,
      startAge: 18,
      endAge: 65
    };

    const updatedState: Dictionary<IncomeSource> = { ...state };

    updatedState[newIncome.id.toString()] = newIncome;

    dispatch(updateIncome(updatedState));
  };

  const onIncomeSourceChange = (change: IncomeSource) => {
    const updatedState: Dictionary<IncomeSource> = { ...state };

    updatedState[change.id] = change;

    dispatch(updateIncome(updatedState));
  };

  const onIncomeSourceDelete = (id: string) => {
    const updatedState: Dictionary<IncomeSource> = {};

    for (const key in state) {
      if (key !== id) updatedState[key] = state[key];
    }

    dispatch(updateIncome(updatedState));
  };

  const incomeItems = Object.values(state).map(incomeSource => (
    <IncomeItem
      key={incomeSource.id}
      data={incomeSource}
      onChange={onIncomeSourceChange}
      onDelete={onIncomeSourceDelete}
    />
  ));

  return (
    <Container style={{ padding: "0px" }}>
      {incomeItems}
      <Row>
        <Col style={{ textAlign: "center" }}>
          <Button onClick={onIncomeSourceAdd}>Add Income</Button>
        </Col>
      </Row>
    </Container>
  );
}

export default Income;
