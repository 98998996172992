import { faBars } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { Navbar } from "react-bootstrap";
import "../../styles/bootstrap.css";

interface NavigationState {
  isNavExpanded: boolean;
}

function Navigation() {
  const [state, setState] = useState<NavigationState>({
    isNavExpanded: false
  });

  const setNavExpanded = (expanded: boolean) => {
    setState({ isNavExpanded: expanded });
  };

  const closeNav = () => {
    setState({ isNavExpanded: false });
  };

  return (
    <Navbar
      expand="lg"
      className="text-uppercase fixed-top navbar-dark bg-primary"
      id="mainNav"
      onToggle={setNavExpanded}
      expanded={state.isNavExpanded}>
      <Navbar.Brand className="text-white" href="#page-top" onClick={closeNav}>
        <h3>
          <strong>Getting Fi</strong>
        </h3>
      </Navbar.Brand>
      <Navbar.Toggle
        aria-controls="basic-navbar-nav"
        className="text-uppercase font-weight-bold rounded">
        <FontAwesomeIcon icon={faBars} />
      </Navbar.Toggle>
    </Navbar>
  );
}

export default Navigation;
