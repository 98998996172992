import { applyMiddleware, combineReducers, createStore } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import thunkMiddleware from "redux-thunk";
import { analysisReducer } from "./analysis/analysisReducer";
import { settingsReducer } from "./settings/settingsReducer";
import { AppData, Settings } from "./types";

const initialState: AppData = {
  settings: {
    numberOfSimulations: 1000,
    timeline: {
      startAge: 65,
      endAge: 90,
    },
    savings: 1000000,
    expenses: [
      {
        amount: 45000,
        threshold: 0,
      },
      {
        amount: 40000,
        threshold: -0.1,
      },
    ],
    allocations: {
      stocks: 0.7,
      bonds: 0.25,
      cash: 0.1,
    },
    income: {
      "1": {
        id: 1,
        title: "Social Security",
        amount: 14000,
        increase: 0.0,
        startAge: 65,
        endAge: 110,
      },
      "2": {
        id: 2,
        title: "Pension",
        amount: 0,
        increase: 0.0,
        startAge: 65,
        endAge: 110,
      },
      "3": {
        id: 3,
        title: "Job",
        amount: 0,
        increase: 0.02,
        startAge: 65,
        endAge: 75,
      },
      "4": {
        id: 4,
        title: "Other",
        amount: 0,
        increase: 0.02,
        startAge: 65,
        endAge: 75,
      }
    },
  },
  analysis: {
    projectionResults: [],
    monteCarloSummary: {
      settings: {} as Settings,
      items: [{ startAmount: 0, count: 0 }],
      samples: [],
      rates: [],
      startAge: 0,
      endAge: 0,
    },
  },
};

const rootReducer = combineReducers({
  settings: settingsReducer,
  analysis: analysisReducer,
});

export type AppState = ReturnType<typeof rootReducer>;

export default function configureAppStore() {
  const middlewares = [thunkMiddleware];
  const middleWareEnhancer = applyMiddleware(...middlewares);

  const store = createStore(
    rootReducer,
    initialState,
    composeWithDevTools(middleWareEnhancer)
  );

  return store;
}
