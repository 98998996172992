export default class Format {
  public static toNumber = (value: number) => {
    if (!value) return "0";

    const formatter = new Intl.NumberFormat("en-US");

    return formatter.format(value);
  };

  public static toDollars = (value: number) => {
    if (!value) return "$0";

    const formatter = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      maximumFractionDigits: 0,
      minimumFractionDigits: 0
    });

    return formatter.format(value);
  };

  public static toPercent = (value: number, decimalPlaces: number) => {
    if (!value) return "0%";

    const formatter = new Intl.NumberFormat("en-US", {
      style: "decimal",
      maximumFractionDigits: decimalPlaces,
      minimumFractionDigits: decimalPlaces
    });

    return `${formatter.format(value)}%`;
  }
}
