import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import { useState } from "react";
import { sliderStyle } from "../../styles";

interface DollarSliderProps {
  value: number;
  onChange(newValue: number): void;
  onAfterChange(): void
}

interface DollarSliderState {
  value: number;
  max: number;
  step: number;
  marks: {};
}

function DollarSlider(props: DollarSliderProps) {
  const calculateState = (value: number): DollarSliderState => {
    if (value > 2499999) {
      return {
        max: 10000000,
        step: 1000,
        value: value,
        marks: {
          0: "$0",
          2500000: "<$2.5M",
          10000000: "$10M"
        }
      };
    }

    if (value > 999999) {
      return {
        max: 2500000,
        step: 1000,
        value: value,
        marks: {
          0: "$0",
          1000000: "<$1M",
          2500000: ">$2.5M"
        }
      };
    }

    if (value > 249999) {
      return {
        max: 1000000,
        step: 1000,
        value: value,
        marks: {
          0: "$0",
          250000: "<$250K",
          1000000: ">$1M"
        }
      };
    }
 
    return {
      max: 250000,
      step: 1000,
      value: value,
      marks: {
        0: "$0",
        250000: ">$250K"
      }   
    };    
  };

  const [localState, setLocalState] = useState<DollarSliderState>(calculateState(props.value));

  const onChange = (value: number) => {
    setLocalState({...localState, value: value})
    props.onChange(value);
  };

  const onAfterChange = (value: number) => {
    setLocalState(calculateState(value));
    props.onAfterChange();
  };

  return (
    <Slider
      value={localState.value}
      min={0}
      max={localState.max}
      step={localState.step}
      marks={localState.marks}      
      handleStyle={
        {
          height: sliderStyle.handleHeight,
          width: sliderStyle.handleWidth,
          marginLeft: sliderStyle.handleMarginLeft,
          marginTop: sliderStyle.handleMarginTop,
          borderColor: sliderStyle.handleDefaultColor,
          borderWidth: sliderStyle.handleBorderWidth
        }
      }
      trackStyle={
        {
          backgroundColor: sliderStyle.trackDefaultColor,
          height: sliderStyle.trackHeight,
          marginTop: sliderStyle.trackMarginTop
        }
      }
      onChange={onChange}
      onAfterChange={onAfterChange}
    />
  );
}

export default DollarSlider;
