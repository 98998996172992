import { Range } from "rc-slider";
import "rc-slider/assets/index.css";
import React, { Fragment, useState } from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import { IncomeSource } from "../../store/types";
import { sliderStyle } from "../../styles";
import colors from "../../styles/bootstrap-colors.module.scss";
import DollarSlider from "./DollarSlider";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import Format from "../Utils/Format";

interface IncomeItemProps {
  data: IncomeSource;
  onChange(change: IncomeSource): void;
  onDelete(id: string): void;  
}

function IncomeItem(props: IncomeItemProps) {
  const [localState, setLocalState] = useState<IncomeSource>(props.data);

  const onChangeAge = (values: number[]) => {
    const changes: IncomeSource = {
      ...localState,
      startAge: values[0],
      endAge: values[1]
    };

    setLocalState(changes);
  };

  const onChangeAmount = (value: number) => {
    const changes: IncomeSource = {
      ...localState,
      amount: value
    };

    setLocalState(changes);
  };

  const onAfterChange = () => {
    props.onChange(localState);
  };

  const getValues = (incomeSource: IncomeSource) => {
    return [incomeSource.startAge, incomeSource.endAge];
  };

  const onDeleteButton = () => {
    props.onDelete(localState.id.toString());
  };

  return (
    <Fragment key={props.data.id}>
      <Row
        style={{
          paddingTop: "0px",
          paddingBottom: "25px"
        }}>
        <Col>
          <Card style={{ border: "0px" }}>
            <Card.Body style={{ padding: "0px" }}>
              <Card.Title className="text-center">
                <Row>
                  <Col md="auto"></Col>
                  <Col style={{width: 200}}>{props.data.title}</Col>
                  <Col md="auto" style={{ textAlign: "right" }}>
                    {props.data.id > 1 && <div onClick={onDeleteButton} style={{cursor: "pointer"}}><FontAwesomeIcon icon={faTrashAlt} /></div>}
                  </Col>
                </Row>
              </Card.Title>
              <Container>
                <Row>
                  <Col
                    style={{
                      paddingLeft: "10px",
                      paddingRight: "10px"
                    }}>
                    <Container>
                      <Row>
                        <Col>
                          <div className="text-center">
                            Start Age
                            <h5
                              style={{
                                color: colors.red
                              }}>
                              {localState.startAge}
                            </h5>
                          </div>
                        </Col>
                        <Col>
                          <div className="text-center">
                            End Age
                            <h5
                              style={{
                                color: colors.blue
                              }}>
                              {localState.endAge}
                            </h5>
                          </div>
                        </Col>
                        <Col>
                          <div className="text-center">
                            Amount
                            <h5
                              style={{
                                color: colors.green
                              }}>
                              {Format.toDollars(localState.amount)}
                            </h5>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col
                          style={{
                            paddingRight: 0,
                            paddingLeft: 0,
                            paddingBottom: "10px"
                          }}>
                          <Range
                            allowCross={false}
                            value={getValues(localState)}
                            min={16}
                            max={110}
                            handleStyle={[
                              {
                                height: sliderStyle.handleHeight,
                                width: sliderStyle.handleWidth,
                                marginLeft: sliderStyle.handleMarginLeft,
                                marginTop: sliderStyle.handleMarginTop,
                                borderColor: colors.red,
                                borderWidth: sliderStyle.handleBorderWidth
                              },
                              {
                                height: sliderStyle.handleHeight,
                                width: sliderStyle.handleWidth,
                                marginLeft: sliderStyle.handleMarginLeft,
                                marginTop: sliderStyle.handleMarginTop,
                                borderColor: colors.blue,
                                borderWidth: sliderStyle.handleBorderWidth
                              }
                            ]}
                            trackStyle={[
                              {
                                backgroundColor: colors.blue,
                                height: sliderStyle.trackHeight,
                                marginTop: sliderStyle.trackMarginTop
                              }
                            ]}
                            onChange={onChangeAge}
                            onAfterChange={onAfterChange}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col
                          style={{
                            paddingRight: 0,
                            paddingLeft: 0
                          }}>
                          <DollarSlider
                            value={localState.amount}
                            onChange={onChangeAmount}
                            onAfterChange={onAfterChange}
                          />
                        </Col>
                      </Row>
                    </Container>
                  </Col>
                </Row>
              </Container>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
}

export default React.memo(IncomeItem);
