import { AnalysisActionTypes, UPDATE_FIXED_ANALYSIS, UPDATE_MONTECARLO_ANALYSIS } from "./analysisActions";
import { Analysis } from "../types";

export function analysisReducer(state = {} as Analysis, action: AnalysisActionTypes): Analysis {
  switch (action.type) {
    case UPDATE_FIXED_ANALYSIS:      
      state.projectionResults = action.result;
      return state;
    case UPDATE_MONTECARLO_ANALYSIS:
      state.monteCarloSummary = action.summary
      return state;
    default:
      return state;
  }
}
