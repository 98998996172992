import Slider from "rc-slider";
import { Dictionary } from "../../store/types";
import { sliderStyle } from "../../styles";

export interface PercentSliderProps {
  value: number;
  min: number,
  max: number,
  step: number,
  color: string;
  marks: Dictionary<string>;
  onChange(newValue: number): void;
  onAfterChange(): void;
}

function PercentSlider(props: PercentSliderProps) {
  return (
    <Slider
      value={props.value}
      min={props.min}
      max={props.max}
      step={props.step}
      marks={props.marks}
      handleStyle={
        {
          height: sliderStyle.handleHeight,
          width: sliderStyle.handleWidth,
          marginLeft: sliderStyle.handleMarginLeft,
          marginTop: sliderStyle.handleMarginTop,
          borderColor: props.color,
          borderWidth: sliderStyle.handleBorderWidth
        }
      }
      trackStyle={
        {
          backgroundColor: props.color,
          height: sliderStyle.trackHeight,
          marginTop: sliderStyle.trackMarginTop
        }
      }
      onChange={props.onChange}
      onAfterChange={props.onAfterChange}
    />
  );
}

export default PercentSlider;
