import { Action } from "redux";
import { ThunkAction } from "redux-thunk";
import { AppState } from "..";
import { MonteCarloSummary, ProjectionResult } from "../types";

export const UPDATE_FIXED_ANALYSIS = "UPDATE_FIXED_ANALYSIS";
export const UPDATE_MONTECARLO_ANALYSIS = "UPDATE_MONTECARLO_ANALYSIS";

export interface UpdateFixedAnalysisAction extends Action {
  type: typeof UPDATE_FIXED_ANALYSIS;
  result: ProjectionResult[];
}

export interface UpdateMonteCarloSummaryAction extends Action {
  type: typeof UPDATE_MONTECARLO_ANALYSIS;
  summary: MonteCarloSummary;
}

export type AnalysisActionTypes =
  | UpdateFixedAnalysisAction
  | UpdateMonteCarloSummaryAction;

export function updateFixedAnalysis(
  results: ProjectionResult[]
): UpdateFixedAnalysisAction {
  return {
    type: UPDATE_FIXED_ANALYSIS,
    result: results
  };
}

export function updateMonteCarloAnalysisSummary(
  summary: MonteCarloSummary
): UpdateMonteCarloSummaryAction {
  return {
    type: UPDATE_MONTECARLO_ANALYSIS,
    summary: summary
  };
}

export function runMonteCarloAnalysis(): ThunkAction<
  void,
  AppState,
  null,
  Action<string>
> {
  return async (dispatch, getState) => {
    const state = getState();

    dispatch(
      updateMonteCarloAnalysisSummary({
        ...state.analysis.monteCarloSummary,
        isCalculating: true
      })
    );

    console.log("runMonteCarloAnalysis...");
    console.time("runMonteCarloAnalysis");

    try {
      const response = await fetch(`${process.env.REACT_APP_API}/api/MonteCarlo`, {
        method: "post",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(state.settings)
      });

      const results: MonteCarloSummary = await response.json(); 

      results.isCalculating = false;

      console.timeEnd("runMonteCarloAnalysis");

      dispatch(updateMonteCarloAnalysisSummary(results));
    } catch (e) {
      console.log(e);
    }
  };
}
