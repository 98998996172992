import { Action } from "redux";
import { ThunkAction } from "redux-thunk";
import { AppState } from "..";
import { runMonteCarloAnalysis } from "../analysis/analysisActions";
import { Allocations, IncomeSource, Timeline, Dictionary, Rate, Expense } from "../types";

export const UPDATE_ALLOCATIONS = "UPDATE_ALLOCATIONS";
export const UPDATE_EXPENSES = "UPDATE_EXPENSES";
export const UPDATE_INCOME = "UPDATE_INCOME";
export const UPDATE_RATES = "UPDATE_RATES";
export const UPDATE_SAVINGS = "UPDATE_SAVINGS";
export const UPDATE_TIMELINE = "UPDATE_TIMELINE";
export const UPDATE_SIMULATIONS = "UPDATE_SIMULATIONS";

export interface UpdateAllocationsAction extends Action {
  type: typeof UPDATE_ALLOCATIONS;
  allocations: Allocations;
}

export interface UpdateExpensesAction extends Action {
  type: typeof UPDATE_EXPENSES;
  expenses: Expense[];
}

export interface UpdateIncomeAction extends Action {
  type: typeof UPDATE_INCOME;
  income: Dictionary<IncomeSource>;
}

export interface UpdateRatesAction extends Action {
  type: typeof UPDATE_RATES;
  rates: Rate;
}

export interface UpdateSavingsAction extends Action {
  type: typeof UPDATE_SAVINGS;
  savings: number;
}

export interface UpdateTimelineAction extends Action {
  type: typeof UPDATE_TIMELINE;
  timeline: Timeline;
}

export interface UpdateSimulationsAction extends Action {
  type: typeof UPDATE_SIMULATIONS;
  numberOfSimulations: number;
}

export type SettingsActionTypes =
  | UpdateAllocationsAction
  | UpdateExpensesAction
  | UpdateExpensesAction
  | UpdateRatesAction
  | UpdateSavingsAction
  | UpdateTimelineAction
  | UpdateIncomeAction
  | UpdateSimulationsAction;

function updateAllocationsInternal(changes: Allocations): UpdateAllocationsAction {
  return {
    type: UPDATE_ALLOCATIONS,
    allocations: changes
  };
}

function updateExpensesInternal(value: Expense[]): UpdateExpensesAction {
  return {
    type: UPDATE_EXPENSES,
    expenses: value
  };
}

function updateIncomeInternal(value: Dictionary<IncomeSource>): UpdateIncomeAction {
  return {
    type: UPDATE_INCOME,
    income: value
  };
}

function updateRatesInternal(changes: Rate): UpdateRatesAction {
  return {
    type: UPDATE_RATES,
    rates: changes
  };
}

function updateSavingsInternal(value: number): UpdateSavingsAction {
  return {
    type: UPDATE_SAVINGS,
    savings: value
  };
}

function updateTimelineInternal(value: Timeline): UpdateTimelineAction {
  return {
    type: UPDATE_TIMELINE,
    timeline: value
  };
}

function updateSimulationsInternal(value: number): UpdateSimulationsAction {
  return {
    type: UPDATE_SIMULATIONS,
    numberOfSimulations: value
  };
}

export function updateExpenses(value: Expense[]): ThunkAction<void, AppState, null, Action<string>> {
  return async dispatch => {
    dispatch(updateExpensesInternal(value)); 
    dispatch(updateSimulations(1000));
  };
}

export function updateSavings(value: number): ThunkAction<void, AppState, null, Action<string>> {
  return async dispatch => {
    dispatch(updateSavingsInternal(value));
    dispatch(updateSimulations(1000));
  };
}

export function updateAllocations(value: Allocations): ThunkAction<void, AppState, null, Action<string>> {
  return async dispatch => {
    dispatch(updateAllocationsInternal(value));
    dispatch(updateSimulations(1000));
  };
}

export function updateRates(value: Rate): ThunkAction<void, AppState, null, Action<string>> {
  return async dispatch => {
    dispatch(updateRatesInternal(value));
    dispatch(updateSimulations(1000));
  };
}

export function updateTimeline(value: Timeline): ThunkAction<void, AppState, null, Action<string>> {
  return async dispatch => {
    dispatch(updateTimelineInternal(value));
    dispatch(updateSimulations(1000));
  };
}

export function updateIncome(value: Dictionary<IncomeSource>): ThunkAction<void, AppState, null, Action<string>> {
  return async dispatch => {
    dispatch(updateIncomeInternal(value));
    dispatch(updateSimulations(1000));
  };
}

export function updateSimulations(value: number): ThunkAction<void, AppState, null, Action<string>> {
  return async dispatch => {
    dispatch(updateSimulationsInternal(value));
    dispatch(runMonteCarloAnalysis());
  };
}