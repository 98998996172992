import { Expense } from "../../store/types";
import "rc-slider/assets/index.css";
import React, { Fragment, useState } from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import colors from "../../styles/bootstrap-colors.module.scss";
import Format from "../Utils/Format";
import PercentSlider from "./PercentSlider";
import DollarSliderSimple from "./DollarSliderSimple";

interface ExpenseItemProps {
  index: number;
  title: string;
  showThreshold: boolean;
  data: Expense;
  onChange(index:number, change: Expense): void;
}

function ExpenseItem(props: ExpenseItemProps) {
  const [localState, setLocalState] = useState<Expense>(props.data);

  const onExpenseChange = (value: number) => {
    const changes: Expense = {
      ...localState,
      amount: value,
    };

    setLocalState(changes);
  };

  const onThresholdChange = (value: number) => {
    const changes: Expense = {
      ...localState,
      threshold: -(value / 100),
    };

    setLocalState(changes);
  };

  const onAfterChange = () => {
    props.onChange(props.index, localState);
  };

  const marks = {
    1: "-1%",
    50: "-50%"
  };

  return (
    <Fragment key={props.index}>
      <Row
        style={{
          paddingTop: "0px",
          paddingBottom: "25px",
        }}
      >
        <Col>
          <Card style={{ border: "0px" }}>
            <Card.Body style={{ padding: "0px" }}>
              <Container>
                <Row noGutters>
                  <Col>
                    <div className="text-center">
                      <h5 style={{ color: colors.green }}>
                        {
                          props.showThreshold ? <span>When investment growth is <span style={{color:colors.blue}}>{Format.toPercent(localState.threshold * 100, 0)}</span> for the year use</span> : <span>Target yearly expenses of </span>} {Format.toDollars(localState.amount)
                        }
                      </h5>
                    </div>
                  </Col>
                </Row>
                <Row noGutters>
                  <Col style={{
                       paddingRight: 0,
                       paddingLeft: 0,
                       paddingBottom: "30px"}}>
                    <DollarSliderSimple
                      min={0}
                      max={200000}
                      step={1000}
                      value={localState.amount}
                      onChange={onExpenseChange}
                      onAfterChange={onAfterChange}
                    />
                  </Col>
                </Row>
                {
                  props.showThreshold ?
                  <Row noGutters>
                    <Col style={{
                         paddingRight: 0,
                         paddingLeft: 0}}>
                        <PercentSlider
                          value={-(localState.threshold * 100)}
                          min={1}
                          max={50}
                          step={1}
                          color={colors.blue}
                          marks={marks}
                          onChange={onThresholdChange}
                          onAfterChange={onAfterChange}/>
                    </Col>
                  </Row>
                  : null
                }
              </Container>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
}

export default ExpenseItem;