import { Settings } from "../types";
import { SettingsActionTypes, UPDATE_ALLOCATIONS, UPDATE_EXPENSES, UPDATE_INCOME, UPDATE_SAVINGS, UPDATE_TIMELINE, UPDATE_SIMULATIONS } from "./settingsActions";

export function settingsReducer(state = {} as Settings, action: SettingsActionTypes): Settings {
  switch (action.type) {
    case UPDATE_ALLOCATIONS: {
      return {
        ...state,
        allocations: action.allocations
      };
    }
    case UPDATE_EXPENSES: {
      return {
        ...state,
        expenses: action.expenses
      };
    }
    case UPDATE_INCOME: {
      return {
        ...state,
        income: action.income
      }
    }
    case UPDATE_SAVINGS: {
      return {
        ...state,
        savings: action.savings
      }
    }
    case UPDATE_TIMELINE: {
      return {
        ...state,
        timeline: action.timeline
      };
    }
    case UPDATE_SIMULATIONS: {
      return {
        ...state,
        numberOfSimulations: action.numberOfSimulations
      };
    }  
    default:
      return state;
  }
}
