import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../store";
import { updateSavings } from "../../store/settings/settingsActions";
import colors from "../../styles/bootstrap-colors.module.scss";
import DollarSlider from "./DollarSlider";
import Format from "../Utils/Format";

function Savings() {
  const state = useSelector<AppState, number>(state => state.settings.savings);
  const dispatch = useDispatch();
  const [localState, setLocalState] = useState<number>(state);

  const onChanged = (value: number): void => {
    setLocalState(value);
  };

  const onAfterChange = () => {
    dispatch(updateSavings(localState));
  };

  return (
    <Container>
      <Row>
        <Col>
          <div className="text-center">
            <h5 style={{ color: colors.green }}>
              {Format.toDollars(localState)} 
            </h5>
          </div>
        </Col>
      </Row>
      <Row noGutters>
        <Col>
          <DollarSlider value={localState} onChange={onChanged} onAfterChange={onAfterChange} />
        </Col>
      </Row>
    </Container>
  );
}

export default Savings;
