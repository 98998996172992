import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { OverlayTrigger, Popover } from "react-bootstrap";
import colors from "../../styles/bootstrap-colors.module.scss";

interface SectionHeaderProps {
  title: string;
  children?: React.ReactNode;
}

function SectionHeader(props: SectionHeaderProps) {
  return (
    <div>
      <div style={{ width: "100%" }}>
        <div style={{ width: "50%", float: "left" }}>
          <h5>{props.title}</h5>
        </div>
        <div style={{ marginLeft: "50%", textAlign: "right" }}>
          <OverlayTrigger
            trigger="hover"
            key="placement"
            placement="bottom"
            overlay={
              <Popover id={`popover-positioned-bottom`}>
                <Popover.Title as="h3">{props.title}</Popover.Title>
                <Popover.Content><div style={{fontSize: "12px"}}>{props.children}</div></Popover.Content>
              </Popover>
            }
          >
            <FontAwesomeIcon
              icon={faQuestionCircle}
              size="lg"
              color={colors.blue}
            />
          </OverlayTrigger>
        </div>
      </div>
      <hr />
    </div>
  );
}

export default SectionHeader;
