import { Range } from "rc-slider";
import "rc-slider/assets/index.css";
import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../store";
import { updateTimeline } from "../../store/settings/settingsActions";
import { Timeline } from "../../store/types";
import { sliderStyle } from "../../styles";
import colors from "../../styles/bootstrap-colors.module.scss";

function Ages() {
  const state = useSelector<AppState, Timeline>(state => state.settings.timeline);
  const dispatch = useDispatch();
  const [localState, setLocalState] = useState<Timeline>(state);

  const onChange = (values: number[]) => {
    const changes: Timeline = {
      startAge: values[0],
      endAge: values[1]
    };

    setLocalState(changes);
  };

  const onAfterChange = () => {
    dispatch(updateTimeline(localState));
  };

  const getValues = (): number[] => {
    return [localState.startAge, localState.endAge];
  };

  return (
    <Container>
      <Row>
        <Col>
          <div className="text-center">
            Start Age
            <h5 style={{ color: colors.red }}>{localState.startAge}</h5>
          </div>
        </Col>
        <Col>
          <div className="text-center">
            End Age
            <h5 style={{ color: colors.blue }}>{localState.endAge}</h5>
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <Range
            allowCross={false}
            value={getValues()}
            min={16}
            max={110}
            marks={{
              16: "16",
              110: "110"
            }}
            handleStyle={[
              {
                height: sliderStyle.handleHeight,
                width: sliderStyle.handleWidth,
                marginLeft: sliderStyle.handleMarginLeft,
                marginTop: sliderStyle.handleMarginTop,
                borderColor: colors.red,
                borderWidth: sliderStyle.handleBorderWidth
              },
              {
                height: sliderStyle.handleHeight,
                width: sliderStyle.handleWidth,
                marginLeft: sliderStyle.handleMarginLeft,
                marginTop: sliderStyle.handleMarginTop,
                borderColor: colors.blue,
                borderWidth: sliderStyle.handleBorderWidth
              }
            ]}
            trackStyle={[
              {
                backgroundColor: colors.blue,
                height: sliderStyle.trackHeight,
                marginTop: sliderStyle.trackMarginTop
              }
            ]}
            onChange={onChange}
            onAfterChange={onAfterChange}
          />
        </Col>
      </Row>
    </Container>
  );
}

export default Ages;
