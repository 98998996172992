import React, { useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useDispatch } from "react-redux";
import Investments from "./components/Investments";
import Navigation from "./components/Navigation";
import { runMonteCarloAnalysis } from "./store/analysis/analysisActions";
import "./styles/bootstrap.css";
import "./styles/sidebar.css";
import AnalysisReport from "./components/Analysis";
import colors from "./styles/bootstrap-colors.module.scss";

function App() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(runMonteCarloAnalysis());
  });

  return (
    <div style={{ backgroundColor: colors.light }}>
      <Navigation />
      <Container style={{ backgroundColor: colors.white }}>
        <Row style={{ paddingTop: 15 }}>
          <Col>
            <h1 className="font-weight-bold">Retirement Analyzer</h1>
            <hr />
            <p>
              <div style={{ fontSize: "125%" }}>
                This retirement analyzer will help those seeking financial
                independence determine how long their investment might last or
                grow. It uses a Monte Carlo simulation executed thousands of
                times with random samplings from historical market returns to
                model the probability of a financial outcome. The future cannot
                truly be predicted but with careful financial planning you can
                increase the probability of achieving your financial goals. Try
                changing the settings below to see how it changes the outcome.
              </div>
            </p>
          </Col>
        </Row>
        <Row style={{ paddingTop: 15 }}>
          <Col>
            <h2>Settings</h2>
            <hr />
          </Col>
        </Row>
        <Row>
          <Col
            className="bd-content"
            style={{ marginLeft: "10px", marginRight: "10px" }}
          >
            <Investments />
          </Col>
        </Row>
        <Row>
          <Col>
            <h2>Analysis</h2>
            <hr />
          </Col>
        </Row>
        <Row>
          <Col
            className="bd-content"
            style={{ marginLeft: "10px", marginRight: "10px" }}
          >
            <AnalysisReport />
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default App;
