import { Range } from "rc-slider";
import "rc-slider/assets/index.css";
import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../store";
import { updateAllocations } from "../../store/settings/settingsActions";
import { Allocations } from "../../store/types";
import { sliderStyle } from "../../styles";
import colors from "../../styles/bootstrap-colors.module.scss";

function Allocation() {
  const state = useSelector<AppState, Allocations>(state => state.settings.allocations);
  const dispatch = useDispatch();
  const [localState, setLocalState] = useState<Allocations>(state);

  const onChange = (values: number[]): void => {
    const newValues = [0, values[1], values[2], 100];
    const newStocks = 100 - (100 - newValues[1]);
    const newCash = 100 - newValues[2];
    const newBonds = 100 - (newStocks + newCash);

    const changes: Allocations = {
      stocks: newStocks / 100,
      bonds: newBonds / 100,
      cash: newCash / 100
    };

    setLocalState(changes);
  };

  const onAfterChange = () => {
    dispatch(updateAllocations(localState));
  };

  const getValues = (): number[] => {
    const stocks = localState.stocks * 100;
    const bonds = localState.bonds * 100;

    return [0, stocks, stocks + bonds, 100];
  };

  return (
    <Container>
      <Row>
        <Col>
          <div className="text-center">
            Stocks
            <h5 style={{ color: colors.red }}>{Math.round(localState.stocks * 100)}%</h5>
          </div>
        </Col>
        <Col>
          <div className="text-center">
            Bonds
            <h5 style={{ color: colors.blue }}>{Math.round(localState.bonds * 100)}%</h5>
          </div>
        </Col>
        <Col>
          <div className="text-center">
            Cash
            <h5 style={{ color: colors.green }}>{Math.round(localState.cash * 100)}%</h5>
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <Range
            allowCross={false}
            value={getValues()}
            handleStyle={[
              { visibility: "hidden" },
              {
                height: sliderStyle.handleHeight,
                width: sliderStyle.handleWidth,
                marginLeft: sliderStyle.handleMarginLeft,
                marginTop: sliderStyle.handleMarginTop,
                borderColor: colors.red,
                borderWidth: sliderStyle.handleBorderWidth
              },
              {
                height: sliderStyle.handleHeight,
                width: sliderStyle.handleWidth,
                marginLeft: sliderStyle.handleMarginLeft,
                marginTop: sliderStyle.handleMarginTop,
                borderColor: colors.blue,
                borderWidth: sliderStyle.handleBorderWidth
              },
              { visibility: "hidden" }
            ]}
            trackStyle={[
              {
                backgroundColor: colors.red,
                height: sliderStyle.trackHeight,
                marginTop: sliderStyle.trackMarginTop
              },
              {
                backgroundColor: colors.blue,
                height: sliderStyle.trackHeight,
                marginTop: sliderStyle.trackMarginTop
              },
              {
                backgroundColor: colors.green,
                height: sliderStyle.trackHeight,
                marginTop: sliderStyle.trackMarginTop
              }
            ]}
            onChange={onChange}
            onAfterChange={onAfterChange}
          />
        </Col>
      </Row>
    </Container>
  );
}

export default Allocation;
