import React from "react";
import { Col, Container, Row, Button } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { MonteCarloSummary } from "../../store/types";
import { AppState } from "../../store";
import BeatLoader from "react-spinners/BeatLoader";
import colors from "../../styles/bootstrap-colors.module.scss";
import SummaryOverview from "./SummaryOverview";
import { updateSimulations } from "../../store/settings/settingsActions";

export default function AnalysisReport() {
  const dispatch = useDispatch();

  const state = useSelector<AppState, MonteCarloSummary>(
    selectorState => selectorState.analysis.monteCarloSummary
  );

  const isLoading = () => {
    if (state.isCalculating) return true;

    return false;
  };

  const onRunAnalysisClicked = () => {
    dispatch(updateSimulations(25_000));
  };

  const renderRunButton = () => {
    if (!state.isCalculating) {
      return (
        <div style={{ height: 20 }}>
          <Button onClick={onRunAnalysisClicked}>Run Detailed Analysis</Button>
        </div>
      );
    } else {
      return (
        <div style={{ paddingTop: 5, height: 20 }}>
          <BeatLoader loading={isLoading()} color={colors.primary} />
        </div>
      );
    }
  };

  return (
    <div>
      <Container>
        <Row>
          <Col>
            <div
              style={{ paddingTop: 20, paddingBottom: 20 }}
              className="text-center"
            >
              {renderRunButton()}
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <SummaryOverview data={state.items} settings={state.settings} />
          </Col>
        </Row>
      </Container>
    </div>
  );
}
