import React from "react";
import { Container } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../store";
import { updateExpenses } from "../../store/settings/settingsActions";
import { Expense } from "../../store/types";
import ExpenseItem from "./ExpenseItem";

function Expenses() {
  const state = useSelector<AppState, Expense[]>(state => state.settings.expenses);
  const dispatch = useDispatch();

  const onChanged = (index: number, value: Expense): void => {
    const changes: Expense[] = [
      ...state
    ];

    changes[index] = value;

    dispatch(updateExpenses(changes))
  };

  const expenseItems = () => {
    let currentIndex = -1;   

    return state.map(row => {
      currentIndex++;

      let title = "Base";
      let showThreshold = false;

      if (currentIndex > 0) {
        title = "Down Market";
        showThreshold = true;
      };

      return (
        <ExpenseItem
          key={currentIndex}
          index={currentIndex}
          title={title}
          showThreshold={showThreshold}
          data={row}
          onChange={onChanged}
        />
      );
    })
  };

  return (
    <Container style={{ padding: "0px" }}>
      {expenseItems()}
    </Container>
  );
}

export default Expenses;
